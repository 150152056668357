
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

import Logo from "@/components/Logo.vue";
import Link from "@/components/Link.vue";
import Button from "@/components/Button.vue";
import AboutPopup from "@/components/AboutPopup.vue";

export default defineComponent({
  components: {
    Logo,
    Link,
    Button,
    AboutPopup,
  },
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });

    const store = useStore();
    const paint = computed(() => store.state.paint.paint);
    const showModal = ref(false);

    const switchLang = () => {
      locale.value = locale.value === "en-US" ? "ru-RU" : "en-US";
      localStorage.setItem("webpaint:selectedLanguage", locale.value);
    };
    const switchTheme = () => store.dispatch("theme/toggle");

    return {
      paint,
      t,
      switchLang,
      switchTheme,
      showModal,
    };
  },
});

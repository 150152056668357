
import { computed, defineComponent } from "vue";

enum Types {
  Header = "header",
}

export default defineComponent({
  props: {
    type: {
      type: String as () => Types,
      default: Types.Header,
    },
    link: {
      type: String,
      default: "",
    },
  },

  setup(props) {
    const isExternal = computed(() => {
      const domain = (url: string) =>
        url.replace("http://", "").replace("https://", "").split("/")[0];
      return !domain(props.link);
    });

    return {
      isExternal,
    };
  },
});

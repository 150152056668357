
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore:disable-next-line */
import vClickOutside from "click-outside-vue3";

export default defineComponent({
  directives: {
    clickOutside: vClickOutside.directive,
  },

  setup() {
    const { t } = useI18n();

    return {
      t,
      version: process.env.VUE_APP_VERSION,
    };
  },
});

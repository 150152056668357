import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f07f1d46"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.isExternal)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: _ctx.link,
        disabled: !_ctx.link,
        class: "link"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      }, 8, ["to", "disabled"]))
    : (_openBlock(), _createElementBlock("a", {
        key: 1,
        href: _ctx.link,
        disabled: !_ctx.link,
        target: "_blank",
        class: "link"
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 8, _hoisted_1))
}